.page {
    padding: 60px 0;
    background: #f7fafd;
  }
  .page .breadcrumbs {
    margin-bottom: 10px;
  }
  .page .breadcrumbs .list-item {
    margin-right: 10px;
  }
  .page .breadcrumbs .list-item:last-of-type {
    margin-right: 0;
  }
  .page .breadcrumbs .list-item:last-of-type .link {
    padding-right: 0;
    pointer-events: none;
    font-weight: 400;
    color: #2c3c4a;
  }
  .page .breadcrumbs .list-item:last-of-type .link:after {
    display: none;
  }
  .page .breadcrumbs .list-item .link {
    padding-right: 10px;
    position: relative;
    color: #5ba044;
    font-weight: 600;
  }
  .page .breadcrumbs .list-item .link:after {
    content: "/";
    position: absolute;
    right: -3px;
    color: #2c3c4a;
  }
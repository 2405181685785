.galleryContainer {
    display: block;
    margin-top:100px;
    margin-bottom:100px;
}

.galleryContainer img{
    height: 400px;
}

.galleryImages{
    padding-top:50px;

}
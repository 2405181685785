.homeContainer {
    display: block;
    margin-top:100px;
}
* {
  margin: 0;
  padding: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  outline: 0;
}
html {
  scroll-behavior: smooth;
}
body,
html {
  overflow-x: hidden;
}
ol,
ul {
  list-style: none;
}
a {
  text-decoration: none;
}
a,
button,
input,
textarea {
  background: 0 0;
  color: inherit;
  font-family: inherit;
  font-size: inherit;
  border: none;
  cursor: pointer;
  -webkit-transition: 0.3s ease-in-out;
  -o-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
}
input[readonly],
textarea[readonly] {
  cursor: default;
}
textarea {
  resize: none;
}
img {
  display: block;
  width: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}
.reviews-ext {
  width: auto !important;
}
@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.fadeIn {
  -webkit-animation: 0.4s ease-in-out fadeIn;
  animation: 0.4s ease-in-out fadeIn;
}
.fadeOut {
  -webkit-animation: 0.4s ease-in-out fadeOut;
  animation: 0.4s ease-in-out fadeOut;
}
html.fixed {
  overflow: hidden;
}
body {
  font-family: OpenSans, sans-serif;
  color: #2c3c4a;
  line-height: 1.45;
  font-size: 18px;
}
.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: Mulish, sans-serif;
  color: #040b11;
}
.h1,
.h2,
.h3,
.h4,
.h5,
h1,
h2,
h3,
h4,
h5 {
  font-weight: 800;
}
.h1,
h1 {
  font-size: 35px;
  line-height: 1.1;
}
.h2,
h2 {
  font-size: 30px;
  line-height: 1.1;
}
.h3,
h3 {
  font-size: 25px;
  line-height: 1.1;
}
.h4,
h4 {
  font-size: 20px;
  line-height: 1.1;
}
.h5,
h5 {
  font-size: 16px;
  line-height: 1.25;
}
.h6,
h6 {
  font-weight: 600;
  font-size: 16px;
  line-height: 1.1;
}
a.h1:focus,
a.h1:hover,
a.h2:focus,
a.h2:hover,
a.h3:focus,
a.h3:hover,
a.h4:focus,
a.h4:hover,
a.h5:focus,
a.h5:hover,
a.h6:focus,
a.h6:hover {
  color: #5ba044;
}
.theme-element {
  background: transparent;
  color: #5ba044;
  border-radius: 8px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  font-family: Mulish, sans-serif;
}
.theme-element.btn {
  height: 48px;
  font-weight: 800;
  font-size: 16px;
  line-height: 1;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  padding: 0 20px;
}
.theme-element.btn:hover {
  -webkit-transform: scale(1.1);
  -ms-transform: scale(1.1);
  transform: scale(1.1);
}
.theme-element--accent {
  color: #fff;
  background: #5ba044;
}
.theme-element--accent .icon {
  color: #fff;
}
.theme-element--light {
  color: #5ba044;
}
.theme-element--light {
  background-color: #f7fafd !important;
}
.brand_logo {
  padding: 10px;
  margin-right: 10px;
  border-radius: 4px;
}
.brand_name {
  font-family: Mulish, sans-serif;
  font-weight: 800;
  font-size: 30px;
}
.socials .list-item {
  margin-right: 30px;
}
.socials .list-item:last-of-type {
  margin-right: 0;
}
.socials .list-item .link {
  display: inline-block;
  font-size: 24px;
}
.socials .list-item .link:hover {
  -webkit-transform: scale(1.1);
  -ms-transform: scale(1.1);
  transform: scale(1.1);
}
.section {
  padding: 60px 0;
}
.section--nopb {
  padding: 60px 0 0;
}
.linebreak {
  display: block;
}
.accent {
  background: #5ba044;
  color: #fff;
}
.icon {
  color: #5ba044;
}
.video-play {
  color: #fff;
  font-size: 30px;
  height: 95px;
  width: 95px;
  border-radius: 50%;
  border: 2px solid #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.video-play .icon {
  color: #fff;
}
.link--arrow {
  color: #5ba044;
  font-weight: 700;
  font-family: Mulish, sans-serif;
  font-size: 16px;
  line-height: 1;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.link--arrow .icon {
  margin-left: 10px;
  -webkit-transition: margin-left 0.3s ease-in-out;
  -o-transition: margin-left 0.3s ease-in-out;
  transition: margin-left 0.3s ease-in-out;
}
.link--arrow:focus .icon,
.link--arrow:hover .icon {
  margin-left: 15px;
}
.underlined {
  display: inline-block;
  position: relative;
  -webkit-transition: text-shadow 0.3s ease-in-out;
  -o-transition: text-shadow 0.3s ease-in-out;
  transition: text-shadow 0.3s ease-in-out;
  padding-bottom: 0;
}
.underlined:after {
  content: "";
  -webkit-transition: width 0.3s ease-in-out;
  -o-transition: width 0.3s ease-in-out;
  transition: width 0.3s ease-in-out;
  height: 2px;
  width: 0;
  position: absolute;
  bottom: 0;
  left: 0;
}
.underlined--white:after {
  background: #fff;
}
.underlined--white.active,
.underlined--white.current,
.underlined--white:focus,
.underlined--white:hover {
  text-shadow: 0 0 0 #fff;
}
.underlined--accent:after {
  background: #5ba044;
}
.underlined--accent.active,
.underlined--accent.current,
.underlined--accent:focus,
.underlined--accent:hover {
  text-shadow: 0 0 0 #5ba044;
}
.underlined.active:after,
.underlined.current:after,
.underlined:focus:after,
.underlined:hover:after {
  width: 100%;
}
.field {
  -webkit-transition: 0.3s ease-in-out;
  -o-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
}
.field:not(.field.booking_group-field) {
  background: #fff;
  border-radius: 8px;
  border: 1px solid #808385;
  height: 56px;
  padding: 0 20px;
}
.field:not(.field.booking_group-field):focus {
  border-color: #5ba044;
}
.field-wrapper .icon {
  color: #808385;
}
.field[data-type="date"] {
  cursor: pointer;
}
.field.error {
  border-color: red !important;
}
.field::-webkit-input-placeholder {
  -webkit-transition: opacity 0.3s ease-in-out;
  transition: opacity 0.3s ease-in-out;
  font-size: 16px;
  line-height: 1.4;
  color: #808385;
}
.field::-moz-placeholder {
  -moz-transition: opacity 0.3s ease-in-out;
  transition: opacity 0.3s ease-in-out;
  font-size: 16px;
  line-height: 1.4;
  color: #808385;
}
.field:-ms-input-placeholder {
  -ms-transition: opacity 0.3s ease-in-out;
  transition: opacity 0.3s ease-in-out;
  font-size: 16px;
  line-height: 1.4;
  color: #808385;
}
.field::-ms-input-placeholder {
  -ms-transition: opacity 0.3s ease-in-out;
  transition: opacity 0.3s ease-in-out;
  font-size: 16px;
  line-height: 1.4;
  color: #808385;
}
.field::placeholder {
  -webkit-transition: opacity 0.3s ease-in-out;
  -o-transition: opacity 0.3s ease-in-out;
  transition: opacity 0.3s ease-in-out;
  font-size: 16px;
  line-height: 1.4;
  color: #808385;
}
.field:focus::-webkit-input-placeholder {
  opacity: 0;
}
.field:focus::-moz-placeholder {
  opacity: 0;
}
.field:focus:-ms-input-placeholder {
  opacity: 0;
}
.field:focus::-ms-input-placeholder {
  opacity: 0;
}
.field:focus::placeholder {
  opacity: 0;
}
.field .icon,
.field::placeholder {
  color: #808385;
}
.booking_group-wrapper {
  position: relative;
}
.booking_group-wrapper .icon {
  display: block;
  position: absolute;
  top: 12px;
}
.booking_group-wrapper .icon.icon-calendar {
  left: 0;
}
.booking_group-wrapper .icon.icon-chevron_down {
  right: 0;
}
.booking_group-field::-webkit-input-placeholder {
  font-size: 16px;
  line-height: 1;
  color: #2c3c4a;
}
.booking_group-field::-moz-placeholder {
  font-size: 16px;
  line-height: 1;
  color: #2c3c4a;
}
.booking_group-field:-ms-input-placeholder {
  font-size: 16px;
  line-height: 1;
  color: #2c3c4a;
}
.booking_group-field::-ms-input-placeholder {
  font-size: 16px;
  line-height: 1;
  color: #2c3c4a;
}
.booking_group-field,
.booking_group-field::placeholder {
  font-size: 16px;
  line-height: 1;
  color: #2c3c4a;
}
.media {
  position: relative;
  overflow: hidden;
}
.media img {
  position: relative;
  z-index: 1;
}
.media_label {
  position: absolute;
  height: 40px;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background: #fff;
  z-index: 10;
  padding: 0 15px;
  font-size: 16px;
  font-weight: 700;
  font-family: Mulish, sans-serif;
  color: #5ba044;
  top: 0;
  right: 0;
  border-radius: 8px 0 0 8px;
}
.media_label--left {
  right: unset;
  left: 0;
  border-radius: 0 8px 8px 0;
}
.media_label--pricing {
  font-weight: 400;
  font-family: OpenSans, sans-serif;
  color: #2c3c4a;
}
.media_label--pricing .price {
  margin-right: 5px;
}
.container {
  max-width: 1170px;
}
.collapse:not(.show) {
  display: none;
}
.collapsing {
  height: 0;
  overflow: hidden;
  -webkit-transition: height 0.3s ease-in-out;
  -o-transition: height 0.3s ease-in-out;
  transition: height 0.3s ease-in-out;
}
.offcanvas {
  position: fixed;
  bottom: 0;
  z-index: 20000;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  max-width: 100%;
  visibility: hidden;
  background-color: #fff;
  background-clip: padding-box;
}
.offcanvas-end {
  top: 0;
  right: 0;
  -webkit-transform: translateX(100%);
  -ms-transform: translateX(100%);
  transform: translateX(100%);
}
.offcanvas.show {
  -webkit-transform: none;
  -ms-transform: none;
  transform: none;
}
.fade {
  -webkit-transition: opacity 0.15s linear;
  -o-transition: opacity 0.15s linear;
  transition: opacity 0.15s linear;
}
.fade:not(.show) {
  opacity: 0;
}
.tab-content > .tab-pane {
  display: none;
}
.tab-content > .active {
  display: block;
}
@media screen and (min-width: 767.98px) {
  .h1,
  h1 {
    font-size: 40px;
    line-height: 1;
  }
  .h2,
  h2 {
    font-size: 35px;
    line-height: 1;
  }
  .h3,
  h3 {
    font-size: 32px;
  }
  .h4,
  h4 {
    font-size: 22px;
    line-height: 1.2;
  }
  .h6,
  h6 {
    line-height: 1.25;
  }
  .section {
    padding: 90px 0;
  }
  .section--blockbg {
    position: relative;
  }
  .section--blockbg .block {
    background: #f7fafd;
    height: 395px;
    width: 70%;
    position: absolute;
    z-index: -1;
    top: 0;
  }
  .section--nopb {
    padding: 90px 0 0;
  }
}
@media screen and (min-width: 991.98px) {
  .h1,
  h1 {
    font-size: 52px;
  }
  .h2,
  h2 {
    font-size: 40px;
  }
  .section {
    padding: 120px 0;
  }
  .section--blockbg .block {
    height: 425px;
  }
  .section--nopb {
    padding: 120px 0 0;
  }
}
@media screen and (min-width: 1199.98px) {
  .section--blockbg .block {
    height: 476px;
    width: calc(50vw + 360px / 2 + 10px);
    left: 0;
  }
}

.gm-style-cc,
.gmnoprint a,
.gmnoprint span {
  display: none;
}
.gmnoprint div {
  background: 0 0 !important;
}
.header {
  height: 80px;
  background-color: #fff;
  z-index: 100000;
  will-change: transform;
  -webkit-transition: 0.4s ease-in-out;
  -o-transition: 0.4s ease-in-out;
  transition: 0.4s ease-in-out;
}
.header.sticky {
  position: fixed;
  width: 100%;
  height: 100px;
  top: 0;
  left: 0;
  -webkit-box-shadow: 0 2px 30px rgba(44, 60, 74, 0.08);
  box-shadow: 0 2px 30px rgba(44, 60, 74, 0.08);
}
.header--pinned {
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(0);
}
.header--unpinned {
  -webkit-transform: translateY(-100%);
  -ms-transform: translateY(-100%);
  transform: translateY(-100%);
}
.header .brand_name {
  color: #5ba044;
}
.header_offcanvas {
  padding: 20px 12px;
  height: 100vh;
  overflow-y: scroll;
  width: 100%;
}
.header_offcanvas-header {
  margin-bottom: 30px;
}
.header_offcanvas-header .close {
  font-size: 30px;
}
.header_trigger {
  font-size: 30px;
  position: absolute;
  right: 12px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  color: #5ba044;
}
.header_nav-list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  font-family: Mulish, sans-serif;
}
.header_nav-list .nav-item {
  font-size: 20px;
  -webkit-transition: color 0.3s ease-in-out;
  -o-transition: color 0.3s ease-in-out;
  transition: color 0.3s ease-in-out;
}
.header_nav-list .nav-item.current,
.header_nav-list .nav-item:focus,
.header_nav-list .nav-item:hover {
  color: #5ba044;
}
.header_nav-list .nav-link {
  line-height: 1;
}
.header_nav-list_item {
  margin-bottom: 20px;
  font-size: 20px;
  font-weight: 600;
  color: #040b11;
}
.header_nav-list_item:last-of-type {
  margin-bottom: 0;
}
.header_nav-list_item .icon {
  position: relative;
  top: 2px;
  margin-left: 5px;
  font-size: 22px;
}
.header_nav-list_item .dropdown-list {
  margin-top: 20px;
  margin-left: 10px;
  font-weight: 600;
}
.header_nav-list_item .dropdown-list .list-item {
  margin-bottom: 20px;
}
.header_nav-list_item .dropdown-list .list-item .nav-item {
  font-size: 18px;
}
.header_nav-list_item .dropdown-list .list-item:last-of-type {
  margin-bottom: 0;
}
.header .socials {
  color: #5ba044;
  margin-top: 40px;
}
.header .socials .list-item .link {
  font-size: 30px;
}
@media screen and (min-width: 991.98px) {
  .header {
    height: 108px;
  }
  .header.sticky {
    height: 80px;
  }
  .header_offcanvas {
    padding: 0;
    height: unset;
    overflow-y: unset;
  }
  .header_offcanvas .close,
  .header_offcanvas .socials,
  .header_offcanvas-header {
    display: none !important;
  }
  .header_nav {
    -webkit-box-ordinal-group: 3;
    -ms-flex-order: 2;
    order: 2;
  }
  .header_nav .nav-item,
  .header_nav .nav-link {
    font-size: 16px;
    margin-right: 70px;
    position: relative;
  }
  .header_nav .nav-item:after,
  .header_nav .nav-link:after {
    position: absolute;
    content: "";
    height: 2px;
    width: 0;
    background: #5ba044;
    left: 0;
    bottom: -8px;
    -webkit-transition: width 0.3s ease-in-out;
    -o-transition: width 0.3s ease-in-out;
    transition: width 0.3s ease-in-out;
  }
  .header_nav .nav-item.current,
  .header_nav .nav-item:focus,
  .header_nav .nav-item:hover,
  .header_nav .nav-link.current,
  .header_nav .nav-link:focus,
  .header_nav .nav-link:hover {
    text-shadow: 0 0 0 #5ba044;
  }
  .header_nav .nav-item.current:after,
  .header_nav .nav-item:focus:after,
  .header_nav .nav-item:hover:after,
  .header_nav .nav-link.current:after,
  .header_nav .nav-link:focus:after,
  .header_nav .nav-link:hover:after {
    width: 100%;
  }
  .header_nav-list {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .header_nav-list_item {
    font-weight: 500;
    margin: 0;
  }
  .header_nav-list_item .nav-link {
    margin-bottom: 0;
  }
  .header_nav-list_item .nav-link .icon {
    display: none;
  }
  .header_nav-list_item .nav-link--contacts {
    padding: 10px 25px;
    background: #ddeaf6;
    color: #5ba044;
    border-radius: 8px;
    font-weight: 700;
    margin-right: 0;
  }
  .header_nav-list_item .nav-link--contacts:active,
  .header_nav-list_item .nav-link--contacts:focus,
  .header_nav-list_item .nav-link--contacts:hover {
    text-shadow: none;
  }
  .header_nav-list_item .nav-link--contacts:after {
    display: none;
  }
  .header_nav .dropdown-menu {
    position: absolute;
    opacity: 0;
    max-height: unset;
    text-align: left;
    visibility: hidden;
    padding-top: 10px;
    -webkit-transition: opacity 0.3s;
    -o-transition: opacity 0.3s;
    transition: opacity 0.3s;
  }
  .header_nav .dropdown-menu .dropdown-list {
    background: #fff;
    -webkit-box-shadow: 0 2px 30px rgba(44, 60, 74, 0.08);
    box-shadow: 0 2px 30px rgba(44, 60, 74, 0.08);
    border-radius: 8px;
    padding: 20px;
    margin: 0;
  }
  .header_nav .dropdown-menu .dropdown-list .list-item[data-main="true"] {
    display: none;
  }
  .header_nav .dropdown-menu .dropdown-list .nav-item {
    font-size: 16px;
    margin-bottom: 30px;
    -webkit-transition: color 0.3s;
    -o-transition: color 0.3s;
    transition: color 0.3s;
  }
  .header_nav .dropdown-menu .dropdown-list .nav-item.current,
  .header_nav .dropdown-menu .dropdown-list .nav-item:focus,
  .header_nav .dropdown-menu .dropdown-list .nav-item:hover {
    text-shadow: none;
  }
  .header_nav .dropdown-menu .dropdown-list .nav-item.current:after,
  .header_nav .dropdown-menu .dropdown-list .nav-item:focus:after,
  .header_nav .dropdown-menu .dropdown-list .nav-item:hover:after {
    display: none;
  }
  .header_nav .dropdown-menu .dropdown-list .nav-item:last-of-type {
    margin-bottom: 0;
  }
  .header_nav .dropdown-menu .dropdown-list .nav-item.active:before,
  .header_nav .dropdown-menu .dropdown-list .nav-item:hover:before {
    display: none;
  }
  .header_nav .dropdown-menu.active {
    opacity: 1;
    visibility: visible;
  }
}
.hero {
  background: #f7fafd;
}
.hero_main-title {
  margin-bottom: 20px;
}
.hero_main-content {
  margin-bottom: 30px;
}
.hero_main-content .line {
  border-left: 3px solid #5ba044;
  margin-right: 17px;
}
.hero_main .booking {
  background: #fff;
  border-radius: 8px;
  padding: 30px;
  -webkit-box-shadow: 0 2px 30px rgba(44, 60, 74, 0.08);
  box-shadow: 0 2px 30px rgba(44, 60, 74, 0.08);
}
.hero_main .booking_group-field,
.hero_main .booking_group-label {
  cursor: pointer;
}
.hero_main .booking_group:last-of-type .booking_group-wrapper {
  margin-bottom: 0;
}
.hero_main .booking_group-wrapper {
  margin-bottom: 15px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  width: 100%;
}
.hero_main .booking_group-wrapper .icon-user {
  top: 9px;
}
.hero_main .booking_group-wrapper .icon-chevron_down {
  color: #0c2a37;
}
.hero_main .booking_group-dropdown {
  width: 100%;
  background: #fff;
}
.hero_main .booking_group-dropdown_wrapper .field {
  padding: 0;
  border: 0;
  max-width: 40px;
  text-align: center;
  font-weight: 600;
}
.hero_main .booking_group-dropdown_wrapper .qty-changer {
  height: 40px;
  width: 40px;
  border-radius: 4px;
  border: 1.5px solid #808385;
  color: #808385;
  font-size: 24px;
  line-height: 1;
  font-weight: 500;
  -webkit-transition: 0.3s ease-in-out;
  -o-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
}
.hero_main .booking_group-dropdown_wrapper .qty-changer[data-disabled="true"] {
  pointer-events: none;
}
.hero_main .booking_group-dropdown_wrapper .qty-changer.qty_minus {
  position: relative;
}
.hero_main .booking_group-dropdown_wrapper .qty-changer.qty_minus:after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  height: 1.5px;
  width: 12px;
  background: #808385;
  -webkit-transition: 0.3s ease-in-out;
  -o-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
}
.hero_main .booking_group-dropdown_wrapper .qty-changer:hover,
.hero_main
  .booking_group-dropdown_wrapper
  .qty-changer:not([data-disabled="true"]) {
  border-color: #5ba044;
  color: #5ba044;
}
.hero_main .booking_group-dropdown_wrapper .qty-changer:hover:after,
.hero_main
  .booking_group-dropdown_wrapper
  .qty-changer:not([data-disabled="true"]):after {
  background: #5ba044;
}
.hero_main .booking_group-field {
  border-bottom: 1px solid #ddeaf6;
  padding: 10px 0 14px 30px;
  width: 100%;
}
.hero_main .booking_btn {
  margin-top: 30px;
  width: 100%;
}
.hero_media {
  display: none;
}
@media screen and (min-width: 575.98px) {
  .hero_main .booking {
    padding: 60px;
  }
  .hero_main .booking .item-wrapper {
    margin: 0 -15px;
  }
  .hero_main .booking_group {
    width: 50%;
    padding: 0 15px;
  }
  .hero_main .booking_group-wrapper {
    position: relative;
  }
  .hero_main .booking_group-dropdown {
    position: absolute;
    top: 100%;
    width: 250px;
    left: 50%;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
    border-radius: 8px;
    -webkit-box-shadow: 0 2px 30px rgba(44, 60, 74, 0.08);
    box-shadow: 0 2px 30px rgba(44, 60, 74, 0.08);
  }
  .hero_main .booking_group-dropdown .content {
    padding: 20px;
  }
  .hero_main .booking_btn {
    margin: 20px 15px 0;
    width: calc(50% - 30px);
  }
}
@media screen and (min-width: 767.98px) {
  .hero {
    background: 0 0;
  }
  .hero_main {
    position: relative;
  }
  .hero_main-title {
    margin-bottom: 30px;
  }
  .hero_main-content {
    margin-bottom: 40px;
    width: 520px;
  }
  .hero_main:before {
    content: "";
    position: absolute;
    top: -90px;
    left: -12px;
    width: 1000px;
    height: 500px;
    background: #f7fafd;
    z-index: -1;
  }
  .hero_main-content,
  .hero_main-title {
    max-width: 570px;
  }
  .hero_main .booking .item-wrapper {
    margin: 0 -30px;
  }
  .hero_main .booking_group {
    padding: 0 30px;
  }
  .hero_main .booking_group-dropdown {
    width: 100%;
  }
  .hero_main .booking_btn {
    margin: 20px 30px 0;
    width: calc(50% - 60px);
  }
}
@media screen and (min-width: 991.98px) {
  .hero {
    padding: 40px 0 120px;
  }
  .hero .container--hero {
    padding-right: 0;
  }
  .hero_main {
    width: 570px;
  }
  .hero_main-content {
    width: 480px;
  }
  .hero_main:before {
    top: 50%;
    width: 1157px;
    height: 574px;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
  }
  .hero_main .booking {
    padding: 0;
    min-width: 968px;
  }
  .hero_main .booking .item-wrapper {
    margin: 0;
    height: 90px;
    -webkit-box-pack: right;
    -ms-flex-pack: right;
    justify-content: right;
  }
  .hero_main .booking_group {
    padding: 0 30px;
  }
  .hero_main .booking_group:first-of-type {
    padding-left: 60px;
    border-right: 2px solid #ddeaf6;
  }
  .hero_main .booking_group:last-of-type {
    padding-right: 0;
    width: calc(50% + 60px);
    border-left: 2px solid #ddeaf6;
  }
  .hero_main .booking_group-wrapper {
    margin-bottom: 0;
  }
  .hero_main .booking_group-field {
    border-bottom: none;
    padding-bottom: 0;
  }
  .hero_main .booking_group-dropdown {
    width: 250px;
    top: calc(100% + 15px);
  }
  .hero_main .booking_btn {
    padding: 0;
    margin: 0;
    height: 60px;
    min-width: 170px;
    max-width: 170px;
  }
  .hero_main .booking_btn:focus,
  .hero_main .booking_btn:hover {
    -webkit-transform: none;
    -ms-transform: none;
    transform: none;
    background: #275f15;
    color: #fff;
  }
  .hero_media {
    display: block;
    width: calc(100% - 680px);
    height: 734px;
    position: relative;
    z-index: -1;
  }
  .hero_media img {
    height: 100%;
  }
}
@media screen and (min-width: 1199.98px) {
  .hero .container--hero {
    max-width: unset;
    margin-right: 0;
    margin-left: calc(50vw - 570px);
    padding: 0;
  }
  .hero_main:before {
    left: -30px;
    border-radius: 8px;
  }
  .hero_main .booking {
    min-width: 850px;
  }
  .hero_main .booking .item-wrapper {
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }
  .hero_main .booking_group {
    width: 200px;
  }
  .hero_main .booking_group:first-of-type {
    width: 230px;
  }
  .hero_main .booking_group:last-of-type {
    width: 260px;
  }
  .hero_main .booking_group:last-of-type .booking_group-wrapper {
    width: unset;
  }
  .hero_main .booking_group-wrapper {
    width: 140px;
  }
}
@media screen and (min-width: 1399.98px) {
  .hero_main:before {
    left: -100px;
  }
}
@media screen and (min-width: 1599.98px) {
  .hero_main:before {
    left: -190px;
  }
}
.rooms_header {
  text-align: center;
  margin-bottom: 30px;
}
.rooms_header-title {
  margin-bottom: 15px;
}
.rooms_header .btn {
  width: 100%;
}
.rooms_list-item {
  margin-bottom: 30px;
}
.rooms_list-item:last-of-type {
  margin-bottom: 0;
}
.rooms_list-item .item-wrapper {
  background: #fff;
  -webkit-box-shadow: 0 2px 30px rgba(44, 60, 74, 0.08);
  box-shadow: 0 2px 30px rgba(44, 60, 74, 0.08);
  border-radius: 8px;
}
.rooms_list-item .item-wrapper .media {
  border-radius: 8px 8px 0 0;
  height: 225px;
}
.rooms_list-item .item-wrapper .media img {
  height: 100%;
}
.rooms_list-item .item-wrapper .media_label {
  bottom: 30px;
  top: unset;
}
.rooms_list-item .item-wrapper .main {
  padding: 20px;
}
.rooms_list-item .item-wrapper .main_title {
  display: inline-block;
  margin-bottom: 15px;
}
.rooms_list-item .item-wrapper .main_amenities {
  margin-bottom: 20px;
}
.rooms_list-item .item-wrapper .main_amenities-item {
  margin-right: 20px;
}
.rooms_list-item .item-wrapper .main_amenities-item:last-of-type {
  margin-top: 0;
}
.rooms_list-item .item-wrapper .main_amenities-item .icon {
  margin-right: 5px;
}
@media screen and (min-width: 575.98px) {
  .rooms_header-title {
    margin-bottom: 0;
  }
  .rooms_list-item .item-wrapper .main {
    padding: 30px;
  }
  .rooms_list-item .item-wrapper .media {
    height: 350px;
  }
}
@media screen and (min-width: 767.98px) {
  .rooms_list {
    margin: 0 -15px;
  }
  .rooms_list-item {
    padding: 0 15px;
  }
  .rooms_list-item .item-wrapper {
    height: 100%;
  }
  .rooms_list-item .item-wrapper .media {
    height: 225px;
  }
  .rooms_list-item .item-wrapper .main_title {
    max-width: 350px;
  }
  .rooms_list-item .item-wrapper .main_amenities {
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
  }
}
.rooms_list-item .card {
  border-radius: 8px;
  padding: 20px;
}
.rooms_list-item .card .title {
  color: #fff;
  margin-bottom: 15px;
}
.rooms_list-item .card .content {
  margin-top: 20px;
  padding-left: 13px;
  border-left: 2px solid #fff;
}
.rooms_list-item .card .content .text {
  margin-bottom: 20px;
  font-size: 16px;
  line-height: 1.4;
}
.rooms_list-item .card .content .text b {
  font-weight: 700;
}
.rooms_list-item .card .content .text:last-of-type {
  margin-bottom: 0;
}
.rooms_list-item .card .btn {
  margin-top: 30px;
  width: 100%;
}
@media screen and (min-width: 575.98px) {
  .rooms_list-item .card {
    padding: 30px;
  }
  .rooms_list-item .card .btn {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
  }
}
@media screen and (min-width: 1199.98px) {
  .rooms_list-item {
    margin-bottom: 0;
  }
  .rooms_list-item .item-wrapper .media {
    height: 280px;
  }
  .rooms_list-item .card {
    padding: 50px;
  }
}
.about_main {
  text-align: center;
  margin-bottom: 40px;
}
.about_main-header {
  margin-bottom: 15px;
}
.about_main-text {
  margin-bottom: 30px;
}
.about_main-list {
  max-width: 200px;
  margin: 0 auto 30px;
}
.about_main-list_item {
  margin-bottom: 20px;
  font-size: 16px;
  line-height: 1.4;
}
.about_main-list_item:last-of-type {
  margin-bottom: 0;
}
.about_main-list_item .icon {
  margin-bottom: 5px;
}
.about_main-action .wrapper,
.about_main-action_item {
  width: 100%;
}
.about_main-action_item.theme-element--accent {
  margin-top: 30px;
}
.about_main-action_item.link--arrow {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.about_media {
  position: relative;
  overflow: hidden;
  height: 220px;
}
.about_media img {
  height: 100%;
  -webkit-transition: 0.5s ease-in-out;
  -o-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
}
.about_media:hover img {
  -webkit-transform: scale(1.1);
  -ms-transform: scale(1.1);
  transform: scale(1.1);
}
@media screen and (min-width: 575.98px) {
  .about_main {
    text-align: left;
  }
  .about_main-list {
    max-width: 540px;
    margin: 0 -25px 40px;
  }
  .about_main-list_item {
    width: 50%;
    padding: 0 25px;
  }
  .about_main-list_item[data-order="3"] {
    margin-bottom: 0;
  }
  .about_main-list_item .icon {
    margin-bottom: 0;
    margin-right: 10px;
  }
  .about_main-list_item .text {
    max-width: 160px;
  }
  .about_main-action .wrapper,
  .about_main-action_item {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
  }
  .about_main-action_item.theme-element--accent {
    margin-top: 0;
    margin-right: 30px;
  }
  .about_main-action_item.link--arrow {
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }
  .about_media {
    height: 350px;
  }
}
@media screen and (min-width: 991.98px) {
  .about_main {
    max-width: 600px;
  }
  .about_media {
    height: 450px;
  }
}
@media screen and (min-width: 1199.98px) {
  .about_main {
    max-width: 500px;
    margin-right: 100px;
    margin-bottom: 0;
  }
  .about_media {
    height: 580px;
    width: 50vw;
  }
  .about_media img {
    width: 100%;
  }
  .about .container--about {
    margin-right: 0;
    margin-left: calc(50vw - 570px);
    max-width: unset;
    padding: 0;
  }
}
.rating {
  padding: 60px 0;
  background: #f7fafd;
}
.rating_list-item {
  text-align: center;
  margin-bottom: 40px;
}
.rating_list-item:last-of-type {
  margin-bottom: 0;
}
.rating_list-item .main {
  margin-bottom: 20px;
}
.rating_list-item .main_rating {
  margin-bottom: 5px;
}
.rating_list-item .media {
  height: 32px;
  overflow: visible;
}
@media screen and (min-width: 575.98px) {
  .rating_list-item[data-order="1"] {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
  }
  .rating_list-item[data-order="2"] {
    -webkit-box-ordinal-group: 4;
    -ms-flex-order: 3;
    order: 3;
    margin-bottom: 0;
  }
  .rating_list-item[data-order="3"] {
    margin-bottom: 0;
    -webkit-box-ordinal-group: 3;
    -ms-flex-order: 2;
    order: 2;
  }
  .rating_list-item[data-order="4"] {
    -webkit-box-ordinal-group: 5;
    -ms-flex-order: 4;
    order: 4;
  }
}
@media screen and (min-width: 1199.98px) {
  .rating_list-item.col-xl-3 {
    margin-bottom: 0;
    -webkit-box-ordinal-group: unset !important;
    -ms-flex-order: unset !important;
    order: unset !important;
    text-align: left;
  }
}
.reviews_header {
  margin-bottom: 30px;
}
.reviews_slider--main {
  overflow: hidden;
  -webkit-box-shadow: 0 2px 30px rgba(44, 60, 74, 0.08);
  box-shadow: 0 2px 30px rgba(44, 60, 74, 0.08);
  background: #fff;
  border-radius: 8px;
  border-left: 3px solid #ddeaf6;
  margin-bottom: 30px;
}
.reviews_slider--main .swiper-slide {
  height: auto;
  padding: 20px;
  background: #fff;
}
.reviews_slider--media {
  display: none;
}
.reviews_slider-slide_stars {
  margin-bottom: 15px;
}
.reviews_slider-slide_stars .icon {
  color: #f7aa00;
  font-size: 22px;
  margin-right: 10px;
}
.reviews_slider-slide_stars .icon:last-of-type {
  margin-right: 0;
}
.reviews_slider-slide_date {
  font-size: 16px;
  line-height: 1.4;
}
.reviews_slider-slide_date .h4 {
  margin-right: 5px;
}
.reviews_slider-slide_main {
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  margin: 30px 0;
}
.reviews_slider-slide_main .title {
  margin-bottom: 5px;
}
.reviews_slider-slide_main .text {
  font-size: 16px;
  line-height: 1.4;
}
.reviews_slider-slide_guest .avatar {
  width: 40px;
  height: 40px;
  border-radius: 8px;
  overflow: hidden;
  margin-right: 15px;
}
.reviews .swiper-button-next,
.reviews .swiper-button-prev {
  width: 40px;
  height: 40px;
  font-size: 20px;
}
.reviews .swiper-button-next .icon,
.reviews .swiper-button-prev .icon {
  -webkit-transition: color 0.3s ease-in-out;
  -o-transition: color 0.3s ease-in-out;
  transition: color 0.3s ease-in-out;
}
.reviews .swiper-button-next:focus,
.reviews .swiper-button-next:hover,
.reviews .swiper-button-prev:focus,
.reviews .swiper-button-prev:hover {
  background: #5ba044;
}
.reviews .swiper-button-next:focus .icon,
.reviews .swiper-button-next:hover .icon,
.reviews .swiper-button-prev:focus .icon,
.reviews .swiper-button-prev:hover .icon {
  color: #fff;
}
.reviews .swiper-button-prev {
  margin-right: 15px;
}
@media screen and (min-width: 575.98px) {
  .reviews_slider--main .swiper-slide {
    padding: 60px;
  }
}
@media screen and (min-width: 991.98px) {
  .reviews .main {
    margin-left: 60px;
  }
  .reviews_slider-slide_guest .avatar {
    display: none;
  }
  .reviews_slider--media {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
  .reviews_slider--media .swiper-slide {
    overflow: hidden;
    border-radius: 8px;
    position: relative;
  }
  .reviews_slider--media .swiper-slide img {
    height: 100%;
  }
}
.promo {
  background: #f7fafd;
}
.promo_info {
  margin-bottom: 40px;
}
.promo_media {
  border-radius: 8px;
  overflow: hidden;
  height: 300px;
  position: relative;
}
.promo_media img {
  height: 100%;
}
.promo .media_card {
  background: #fff;
  -webkit-box-shadow: 0 2px 30px rgba(44, 60, 74, 0.08);
  box-shadow: 0 2px 30px rgba(44, 60, 74, 0.08);
  border-radius: 8px;
  padding: 30px;
  display: none;
}
.promo .media_card .avatar {
  display: block;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  overflow: hidden;
  margin-right: 15px;
}
.promo .media_card .avatar img {
  width: 100%;
  height: 100%;
}
.promo .media_card-text {
  margin-bottom: 15px;
}
.promo .media_card-btn {
  margin-top: 20px;
}
.promo .media_card-footer .stars {
  margin-bottom: 5px;
}
.promo .media_card-footer .stars .icon {
  margin-right: 10px;
  font-size: 24px;
  color: #f7aa00;
}
.promo .media_card-footer .stars .icon:last-of-type {
  margin-right: 0;
}
.promo .info_header {
  margin-bottom: 15px;
}
.promo .info_list .list-item,
.promo .info_text {
  margin-bottom: 30px;
}
.promo .info_list .list-item:last-of-type {
  margin-bottom: 0;
}
.promo .info_list .list-item .media {
  height: 80px;
  width: 80px;
  margin-right: 20px;
}
.promo .info_list .list-item .main {
  width: calc(100% - 100px);
}
.promo .info_list .list-item .main_title {
  margin-bottom: 5px;
}
.promo .info_list .list-item .main_text {
  max-width: 180px;
  font-size: 16px;
  line-height: 1.4;
}
@media screen and (min-width: 575.98px) {
  .promo_media {
    height: 500px;
  }
  .promo .info_list .list-item .media {
    height: 80px;
    width: 80px;
    margin-bottom: 0;
    margin-right: 20px;
  }
  .promo .info_list .list-item .main_text {
    max-width: 190px;
  }
}
@media screen and (min-width: 767.98px) {
  .promo_info {
    width: 500px;
    margin-bottom: 90px;
  }
  .promo_media {
    width: 570px;
    height: 614px;
    margin: 0 auto;
    position: relative;
    overflow: visible;
    border-radius: unset;
  }
  .promo_media img {
    position: relative;
    z-index: 2;
    border-radius: 8px;
  }
  .promo .media_card {
    display: block;
    position: absolute;
    z-index: 5;
    width: 353px;
  }
  .promo .media_card--bottom {
    bottom: 60px;
    right: 60%;
    padding: 50px;
    width: 300px;
  }
  .promo .media_card--top {
    top: 60px;
    left: 50%;
  }
}
@media screen and (min-width: 991.98px) {
  .promo_media {
    width: 100%;
  }
  .promo_media img {
    width: 570px;
    margin: 0 auto;
  }
  .promo .media_card--bottom {
    left: 0;
    right: unset;
  }
  .promo .media_card--top {
    right: 0;
    left: unset;
  }
}
@media screen and (min-width: 1199.98px) {
  .promo_info {
    margin-bottom: 0;
  }
  .promo_media {
    width: 50%;
    margin: 0;
  }
  .promo .media_card--bottom {
    right: 70%;
    left: unset;
  }
  .promo .media_card--top {
    left: 41%;
    right: unset;
  }
}
@media screen and (min-width: 1399.98px) {
  .promo .media_card--bottom {
    right: 73%;
  }
  .promo .media_card--top {
    left: 60%;
  }
}
@media screen and (min-width: 1599.98px) {
  .promo .media_card--top {
    left: 71%;
  }
}
.gallery {
  padding-bottom: 0;
}
.gallery_header {
  text-align: center;
  margin-bottom: 30px;
}
.gallery_header-title {
  margin-bottom: 15px;
}
.gallery_header .btn {
  width: 100%;
}
.gallery_grid {
  grid-gap: 30px;
}
.gallery_grid-item_img {
  -webkit-transition: 0.4s ease-in;
  -o-transition: 0.4s ease-in;
  transition: 0.4s ease-in;
  height: 100%;
  z-index: -1;
  position: relative;
}
.gallery_grid-item [data-role="gallery-link"] {
  border-radius: 8px;
  position: relative;
  overflow: hidden;
  display: block;
  height: 100%;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
}
.gallery_grid-item [data-role="gallery-link"]:focus .overlay,
.gallery_grid-item [data-role="gallery-link"]:hover .overlay {
  opacity: 1;
}
.gallery_grid-item [data-role="gallery-link"]:focus .gallery_grid-item_img,
.gallery_grid-item [data-role="gallery-link"]:hover .gallery_grid-item_img {
  -webkit-transform: scale(1.1);
  -ms-transform: scale(1.1);
  transform: scale(1.1);
}
.gallery_grid-item .overlay {
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
  padding: 40px;
  background: rgba(35, 87, 132, 0.5);
  -webkit-transition: opacity 0.3s ease-in-out;
  -o-transition: opacity 0.3s ease-in-out;
  transition: opacity 0.3s ease-in-out;
  opacity: 0;
}
.gallery_grid-item .overlay_focus {
  color: #fff;
}
@media screen and (min-width: 575.98px) {
  .gallery_header {
    text-align: left;
  }
  .gallery_header-title {
    margin-bottom: 0;
  }
  .gallery_header .btn {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
  }
  .gallery_grid {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media screen and (min-width: 767.98px) {
  .gallery {
    padding-bottom: 90px;
  }
}
@media screen and (min-width: 991.98px) {
  .gallery {
    padding-bottom: 120px;
  }
  .gallery_grid {
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(2, 245px);
  }
  .gallery_grid-item--left {
    grid-area: 1/1/3/2;
  }
  .gallery_grid-item--right {
    grid-area: 1/3/3/4;
  }
}
.latest .block {
  height: 443px;
  display: none;
}
.latest_header {
  text-align: center;
  margin-bottom: 30px;
}
.latest_header-title {
  margin-bottom: 15px;
}
.latest_header .btn {
  width: 100%;
}
.latest_list-item {
  margin-bottom: 30px;
}
.latest_list-item:last-of-type {
  margin-bottom: 0;
}
.latest_list-item .item-wrapper {
  -webkit-box-shadow: 0 2px 30px rgba(44, 60, 74, 0.08);
  box-shadow: 0 2px 30px rgba(44, 60, 74, 0.08);
  border-radius: 8px;
}
.latest_list-item .item-wrapper .media {
  border-radius: 8px 8px 0 0;
  height: 225px;
}
.latest_list-item .item-wrapper .media img {
  height: 100%;
}
.latest_list-item .item-wrapper .media_label {
  top: 30px;
}
.latest_list-item .item-wrapper .main {
  padding: 20px;
  background: #fff;
  border-radius: 0 0 8px 8px;
}
.latest_list-item .item-wrapper .main_title {
  margin-bottom: 15px;
  display: inline-block;
}
.latest_list-item .item-wrapper .main_preview {
  margin-bottom: 20px;
}
.latest_list-item .item-wrapper .main_metadata-item {
  font-size: 16px;
  line-height: 1.4;
  margin-right: 20px;
}
.latest_list-item .item-wrapper .main_metadata-item:last-of-type {
  margin-right: 0;
}
.latest_list-item .item-wrapper .main_metadata-item .icon {
  margin-right: 5px;
  font-size: 16px;
}
.latest_list-item .item-wrapper .main_metadata-item .icon-comment,
.latest_list-item .item-wrapper .main_metadata-item .icon-eye {
  font-size: 14px;
}
.latest_list-item .item-wrapper .main_metadata-item .icon-calendar {
  margin-right: 7px;
}
.latest_list-item .item-wrapper .main_metadata-item .text {
  display: none;
}
@media screen and (min-width: 575.98px) {
  .latest_header-title {
    margin-bottom: 0;
  }
  .latest_header .btn {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
  }
  .latest_list-item .item-wrapper .main {
    padding: 30px;
  }
  .latest_list-item .item-wrapper .main_metadata .text {
    display: inline;
    margin-left: 5px;
  }
  .latest_list-item .item-wrapper .media {
    height: 350px;
  }
}
@media screen and (min-width: 767.98px) {
  .latest .block {
    display: block;
  }
  .latest_list {
    margin: 0 -15px;
  }
  .latest_list-item {
    padding: 0 15px;
  }
  .latest_list-item .item-wrapper {
    height: 100%;
  }
  .latest_list-item .item-wrapper .media {
    height: 245px;
  }
}
@media screen and (min-width: 1199.98px) {
  .latest_list-item {
    margin-bottom: 0;
  }
}
.contacts_info {
  margin-bottom: 40px;
}
.contacts_info-header {
  margin-bottom: 30px;
}
.contacts_info-header_title {
  margin-bottom: 15px;
}
.contacts_info-list_item {
  margin-bottom: 30px;
  font-size: 16px;
  line-height: 1.4;
}
.contacts_info-list_item:last-of-type {
  margin-bottom: 0;
}
.contacts_info-list_item .media {
  width: 80px;
  height: 80px;
  margin-right: 20px;
  font-size: 30px;
}
.contacts_info-list_item .main_title {
  margin-bottom: 5px;
}
.contacts_map {
  height: 400px;
  margin: 0 -12px;
  margin-bottom: 250px;
}
.contacts_map #map {
  height: 100%;
}
@media screen and (min-width: 767.98px) {
  .contacts_info-header_text {
    max-width: 430px;
  }
  .contacts_info-list_item[data-order="3"] {
    margin-bottom: 0;
  }
  .footer_main {
    padding: 240px 0 70px 0;
  }
}
@media screen and (min-width: 1199.98px) {
  .contacts {
    padding-bottom: 120px;
  }
  .contacts .container--contacts {
    margin-right: 0;
    margin-left: calc(50vw - 570px);
    max-width: unset;
    padding: 0;
  }
  .contacts_info {
    margin-bottom: 0;
    width: 600px;
  }
  .contacts_info-list {
    width: 100%;
    margin: 0 -10px;
  }
  .contacts_info-list_item {
    min-width: 280px;
    padding: 0 10px;
  }
  .contacts_map {
    margin: 0 0 0 60px;
    height: 620px;
    width: 50vw;
  }
}
.contacts {
  padding: 0 !important;
}

.footer_main {
  padding: 60px 0;
}
.footer_main-block {
  margin-bottom: 30px;
}
.footer_main-block .icon {
  color: #fff;
}
.footer_main-block .brand {
  margin-bottom: 15px;
}
.footer_main-block:last-of-type {
  margin-bottom: 0;
}
.footer_main-block_header {
  color: #fff;
  margin-bottom: 15px;
}
.footer_main-block_nav .list-item {
  margin-right: 20px;
}
.footer_main-block_nav .list-item:last-of-type {
  margin-right: 0;
}
.footer_main-block_contacts .list-item {
  margin-bottom: 20px;
}
.footer_main-block_contacts .list-item:last-of-type {
  margin-bottom: 0;
}
.footer_main-block_contacts .list-item .icon {
  font-size: 24px;
  margin: 5px 20px 0 0;
}
.footer_main-block_contacts .list-item .icon-call {
  width: 24px;
}
.footer_main-block_contacts .list-item .icon-call svg {
  width: 20px;
}
.footer_main-block .socials {
  margin-top: 30px;
}
.footer_copyright {
  font-size: 16px;
  line-height: 1.4;
  padding: 30px 0;
  text-align: center;
  background: #f7fafd;
  color: #2c3c4a;
}
@media screen and (min-width: 575.98px) {
  .footer_main {
    margin: 0 -15px;
  }
  .footer_main-block {
    padding: 0 15px;
  }
  .footer_main-block--about {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
  }
  .footer_main-block--contacts {
    -webkit-box-ordinal-group: 3;
    -ms-flex-order: 2;
    order: 2;
    margin-top: 10px;
  }
  .footer_main-block--follow {
    -webkit-box-ordinal-group: 5;
    -ms-flex-order: 4;
    order: 4;
  }
  .footer_main-block--nav {
    -webkit-box-ordinal-group: 4;
    -ms-flex-order: 3;
    order: 3;
  }
}
@media only screen and (max-width: 600px) {
  .footer_main {
    padding: 240px 0 70px 0;
  }
  .reviews-ext {
    margin: 0 auto;
  }
}
@media screen and (min-width: 767.98px) {
  .footer_main {
    padding: 60px 0;
  }
  .footer_main-block_header {
    margin-bottom: 30px;
  }
  .footer_main-block_nav .list-item {
    margin-bottom: 10px;
  }
  .footer_main-block_nav .list-item:last-of-type {
    margin-bottom: 0;
  }
  .footer_copyright .linebreak {
    display: inline;
  }
}
@media screen and (min-width: 991.98px) {
  .footer_main {
    margin: 0;
  }
  .footer_main-block {
    padding: 0;
    margin-bottom: 0;
  }
  .footer_main-block_header {
    margin-top: 8px;
  }
  .footer_main-block--about {
    max-width: 280px;
  }
  .footer_main-block--nav {
    -webkit-box-ordinal-group: 3;
    -ms-flex-order: 2;
    order: 2;
  }
  .footer_main-block--contacts {
    margin-top: 0;
    -webkit-box-ordinal-group: 4;
    -ms-flex-order: 3;
    order: 3;
  }
  .footer_main-block--follow {
    max-width: 180px;
  }
}
.modal {
  z-index: 1000000;
}
.modal.swal2-backdrop-show {
  background: rgba(35, 87, 132, 0.6);
}
.modal_popup {
  background: #fff;
  border-radius: 8px;
  position: relative;
}
.modal_popup--promo {
  overflow: hidden;
}
.modal_popup--promo .modal_popup-close {
  position: absolute;
  font-size: 32px;
  top: 20px;
  right: 20px;
  color: #000;
}
.modal_popup--promo .main {
  padding: 30px;
}
.modal_popup--promo .main_subtitle {
  color: #5ba044;
  margin-bottom: 30px;
  height: 46px;
  border-left: 3px solid #5ba044;
  padding-left: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.modal_popup--promo .main_title {
  font-size: 25px;
}
.modal_popup--promo .main_text {
  font-size: 17px;
  margin: 15px 0 20px;
}
.modal_popup--promo .main_btn {
  margin-top: 30px;
  width: 100%;
}
.modal_popup--promo .main .timer_block {
  height: 55px;
  width: 55px;
  font-size: 14px;
  line-height: 1;
  margin-right: 10px;
}
.modal_popup--promo .main .timer_block:last-of-type {
  margin-right: 0;
}
.modal_popup--promo .main .timer_block .full {
  display: none;
}
.modal_popup--promo .main .timer_block-number {
  color: #5ba044;
}
.modal_popup--promo .media {
  display: none;
}
.alert_popup {
  background: #fff;
  -webkit-box-shadow: 0 2px 30px rgba(44, 60, 74, 0.08);
  box-shadow: 0 2px 30px rgba(44, 60, 74, 0.08);
  border-radius: 8px;
  min-width: 280px;
  max-width: 360px;
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  padding: 30px;
  position: relative;
  z-index: 1000000;
}
.alert_popup-close {
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-transition: color 0.3s ease-in-out;
  -o-transition: color 0.3s ease-in-out;
  transition: color 0.3s ease-in-out;
  position: absolute;
  top: 15px;
  right: 15px;
}
.alert_popup-close:focus,
.alert_popup-close:hover {
  color: #5ba044;
}
@media screen and (min-width: 767.98px) {
  .modal_popup--promo .main_title {
    font-size: 40px;
    line-height: 44px;
  }
  .modal_popup--promo .main_text {
    margin: 20px 0 30px;
  }
  .modal_popup--promo .main_btn {
    margin-top: 40px;
  }
}
.video {
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(35, 87, 132, 0.8);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 400000;
  visibility: hidden;
  -webkit-transition: 0.4s ease-in-out;
  -o-transition: 0.4s ease-in-out;
  transition: 0.4s ease-in-out;
}
.video.visible {
  visibility: visible;
}
.video_frame {
  position: relative;
}
.video_frame-close {
  position: absolute;
  right: 0;
  top: -30px;
  font-size: 24px;
  color: #fff;
  cursor: pointer;
}
.video_frame iframe {
  border: none;
  width: 100%;
  height: 100%;
}
@media screen and (min-width: 575.98px) {
  .modal_popup--promo .modal_popup-close {
    top: 30px;
    right: 30px;
  }
  .modal_popup--promo .main {
    padding: 60px;
  }
  .modal_popup--promo .main_btn {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
  }
  .modal_popup--promo .main .timer_block {
    height: 80px;
    width: 80px;
    margin-right: 15px;
  }
  .modal_popup--promo .main .timer_block .short {
    display: none;
  }
  .modal_popup--promo .main .timer_block .full {
    display: block;
  }
  .modal_popup--promo .main .timer_block-number {
    margin-bottom: 2px;
  }
  .video_frame {
    height: 500px;
  }
}
@media screen and (min-width: 991.98px) {
  .modal_popup--promo {
    max-height: 600px;
    max-width: 970px;
  }
  .modal_popup--promo .main_title {
    font-size: 40px;
    line-height: 44px;
  }
  .modal_popup--promo .main_text {
    margin: 20px 0 30px;
  }
  .modal_popup--promo .main_btn {
    margin-top: 40px;
  }
  .modal_popup--promo .media {
    display: block;
  }
  .modal_popup--promo .media img {
    height: 100%;
  }
  .video_frame {
    height: 700px;
  }
}
/*# sourceMappingURL=../sourcemaps/index.css.map */
.guestLeaf {
  width: 82px;
  height: 132px;
  text-align: right;
}

.swiper-button-next,
.swiper-button-prev{
  top:unset !important;
  left:unset !important;
  bottom:unset !important;
  width: auto !important;
  right: unset !important;
  position: unset !important;
}

.swiper-button-next:hover,
.swiper-button-prev:hover{
  background-color: white !important;
}

.button-swiper {
 width:150px;
 justify-content: space-between;
 display: flex;
}

.button-Arrangement{
  position: absolute;
  top:20px;
  right:10px;
}

.swipercontroller{
  position: relative;
}
/************ Navbar Css ************/
nav.navbar {
    padding: 18px 0;
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 9999;
    transition:  0.32s ease-in-out;
    background-color: white;
  }
  nav.navbar.scrolled {
    padding: 0px 0;
    background-color: #121212;
  }
  nav.navbar a.navbar-brand {
    padding: 10px;
    margin-right: 10px;
    border-radius: 4px;
  }

  .nav-link {
    font-size: 20px;
    -webkit-transition: color 0.3s ease-in-out;
    -o-transition: color 0.3s ease-in-out;
    transition: color 0.3s ease-in-out;
    position: relative;
  }

  .nav-link::after{
    content: '';
    height: 2px;
    width:0%;
    background-color: #7bbb42;
    position: absolute;
    bottom:0;
    left:0; 
    -webkit-transition: width 0.3s ease-in-out;
    -o-transition: width 0.3s ease-in-out;
    transition: width 0.3s ease-in-out;
  }

.nav-link:hover::after{
    width:100%;
}

.nav-link:hover{
    color: #7bbb42  !important;

}

.active{
  background-color: red;
}
.linksContainer {
    display: block;
    margin-top:100px;
    margin-bottom:100px;
}

.LinksList{
  margin-top:20px;
}

  .container {
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
  }
  
  .ct-row {
    margin-left: -5px;
    margin-right: -5px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
  
  .ct-sectional-box {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    max-width: 100%;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    padding: 0 5px 5px 5px;
  }
  
  .ct-sectional-box .title {
    font-family: Arial Narrow, Arial, sans-serif;
    font-weight: 600;
    position: relative;
    font-size: 24px;
    margin: 5px 0 10px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    line-height: 0.9;
    min-height: 87px;
    text-transform: uppercase;
    -webkit-transition: background-color 0.15s ease-in-out;
    transition: background-color 0.15s ease-in-out;
  }
  
  .ct-sectional-box .title::after {
    content: '';
    background: url(https://raw.githubusercontent.com/solodev/column-links/master/images/arrow-right-white.png);
    width: 14px;
    height: 27px;
    position: absolute;
    right: 30px;
    top: 50%;
    margin-top: -13.5px;
  }
  
  .ct-sectional-box .title a {
    display: block;
    padding: 10px 100px 10px 34px;
    max-width: 100%;
    color: inherit;
    position: relative;
    z-index: 1;
    -webkit-box-flex: 1;
    -ms-flex: 1 0 auto;
    flex: 1 0 auto;
  }
  
  .ct-sectional-box .title a:hover {
    text-decoration: none;
  }
  
  .ct-sectional-box .inner {
    background: #faf9f9;
    -webkit-box-flex: 1;
    -ms-flex: 1 0 auto;
    flex: 1 0 auto;
  }
  
  .ct-sectional-box ul {
    font-size: 18px;
    padding: 30px 20px 30px 55px;
  }
  
  ul > li,
  ol > li {
    padding: 6px 0;
  }
  
  .ct-sectional-box ul a {
    text-decoration: underline;
    font-family: Tahoma, Verdana, Segoe, sans-serif;
    color: #00689b;
  }
  
  .ct-sectional-box ul a:hover {
    color: #00719b;
  }
  

  /* box green */
  
  .ct-sectional-box--green .title {
    background: #5ba044;
    color: #fff;
  }
  
  
  /* Media Queries */
  
  @media (min-width: 992px) {
    .ct-sectional-box {
      -webkit-box-flex: 1;
      -ms-flex: 1 1 33.3333%;
      flex: 1 1 33.3333%;
      max-width: 33.3333%;
    }
  }
  
  @media (max-width: 991px) {
    .ct-row {
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
    }
  }
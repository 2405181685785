.amenitiesContainer {
    display: block;
    margin-top: 100px;
}

.card-img-top {
    width: 100%;
    object-fit: contain;
}

.amenitiesList{
    padding-top:50px;
}

.amenityImg{
    height:300px;
}
.text-small {
    font-size: 0.9rem;
    color: white !important;
  }
  
  a {
    color: inherit;
    text-decoration: none;
    transition: all 0.3s;
  }
  
  a:hover, a:focus {
    text-decoration: none;
  }
  
  .form-control {
    background: #212529;
    border-color: #545454;
  }
  
  .form-control:focus {
    background: #212529;
  }
  
  footer {
    background: #5ba044;
    color:white;
  }
  
  